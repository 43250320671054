// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./Ds2BlpVLg-0.js";

const cycleOrder = ["hEADhXkN1"];

const serializationHash = "framer-oNH7t"

const variantClassNames = {hEADhXkN1: "framer-v-xltdjy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({advancedPrice, height, id, width, ...props}) => { return {...props, VMwxxgUeC: advancedPrice ?? props.VMwxxgUeC ?? "€1000"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;advancedPrice?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VMwxxgUeC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hEADhXkN1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xltdjy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hEADhXkN1"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dlaXN0IE1lZGl1bQ==", "--framer-font-family": "\"Geist Medium\", \"Geist Medium Placeholder\", sans-serif", "--framer-font-size": "48px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77)))"}}>€1000</motion.p></React.Fragment>} className={"framer-19epwns"} fonts={["CUSTOM;Geist Medium"]} layoutDependency={layoutDependency} layoutId={"E7YjjsWAR"} style={{"--extracted-r6o4lv": "var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={VMwxxgUeC} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oNH7t.framer-5u16aj, .framer-oNH7t .framer-5u16aj { display: block; }", ".framer-oNH7t.framer-xltdjy { height: 115px; overflow: visible; position: relative; width: 153px; }", ".framer-oNH7t .framer-19epwns { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115
 * @framerIntrinsicWidth 153
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"VMwxxgUeC":"advancedPrice"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDs2BlpVLg: React.ComponentType<Props> = withCSS(Component, css, "framer-oNH7t") as typeof Component;
export default FramerDs2BlpVLg;

FramerDs2BlpVLg.displayName = "Advanced";

FramerDs2BlpVLg.defaultProps = {height: 115, width: 153};

addPropertyControls(FramerDs2BlpVLg, {VMwxxgUeC: {defaultValue: "€1000", displayTextArea: false, title: "Advanced price", type: ControlType.String}} as any)

addFonts(FramerDs2BlpVLg, [{explicitInter: true, fonts: [{family: "Geist Medium", source: "custom", url: "https://framerusercontent.com/assets/cQQzQe0n7xYuuDfkgxZD2N9Q.woff2"}]}], {supportsExplicitInterCodegen: true})